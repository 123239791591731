import React, { useContext } from "react";
import { graphql } from "gatsby";
import UnitDetails from "components/database/unit-details";
import UnitLayout from "components/unit-layout";
import { LocaleContext } from "context/locale-context";
import Seo from "components/seo";

function UnitTemplate({ pageContext, data, location }) {
  const locale = useContext(LocaleContext);
  let isModal = false;
  const isRTL = locale === "ar";
  const title = isRTL
    ? `وثيقة بصرية للحادثة ${data.unitsJson.incident_code}`
    : `Observation of ${data.unitsJson.incident_code} Incident`;
  // We don't want to show the modal if a user navigates
  // directly to a unit so if this code is running on Gatsby's
  // initial render then we don't show the modal, otherwise we
  // do.
  if (
    typeof window !== "undefined" &&
    window.___MNEMONIC_INITIAL_RENDER_COMPLETE
  ) {
    isModal = true;
  }

  return (
    <UnitLayout location={location} isModal={isModal}>
      <Seo metadata={{ title }} />
      <UnitDetails unit={data.unitsJson} locale={locale} />
    </UnitLayout>
  );
}

export default UnitTemplate;

export const pageQuery = graphql`
  query ($id: ID!) {
    unitsJson(jsonId: { eq: $id }) {
      jsonId
      incident_date
      acquired_from
      filename
      incident_code
      is_graphic
      location_ar
      location_en
      online_link
      summary_ar
      summary_en
      weapons_used_0
    }
  }
`;
